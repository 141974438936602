import { Controller } from 'stimulus';
import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

export default class extends Controller {
  connect() {
    $(this.element).dropzone({
      url: '/',
      acceptedFiles: 'image/*',
      maxFilesize: 9,
      autoProcessQueue: false,
      addRemoveLinks: true,
      thumbnail: function(file, dataURL) {
        $(file.previewElement).find('img').attr('src', dataURL);
        $(document).trigger('dropzoneMultiController.addFile', [this.element, file, dataURL])
      },
      removedfile: function(file) {
        $(document).trigger('dropzoneMultiController.removeFile', [this.element, file])
        file.previewElement.remove();
      }
    })
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["availabilityTemplate", "productsTemplate", "productsContainer", "availabilityContainer",
    "hasAlternative", "outStockReason", "imageName", "imageData", "dropzone"]

  connect() {
    $(document).on('dropzoneController.addFile', (event, element, file) => {
      const index = this.dropzoneTargets.findIndex(d => d === element);
      this.imageNameTargets[index].value = file.name;
      this.imageDataTargets[index].value = file.dataURL;
    })
  }

  renderProducts() {
    if($(this.hasAlternativeTarget).is(':checked')) {
      this.productsContainerTarget.innerHTML = this.productsTemplateTarget.innerHTML
    } else {
      this.productsContainerTarget.innerHTML = ''
    }
  }

  renderAvailability() {
    if ($(this.hasAlternativeTarget).is(':checked') && $(this.outStockReasonTarget).is(':checked')) {
      this.availabilityContainerTarget.innerHTML = this.availabilityTemplateTarget.innerHTML
    } else {
      this.availabilityContainerTarget.innerHTML = ''
    }
  }
}

import introJs from 'intro.js'

const tourOptions = {
  showBullets: true,
  showStepNumbers: true,
  keyboardNavigation: false,
  disableInteraction: true,
  steps: [
    {
      step: 1,
      intro:
        "Hello this is a simple step-by-step guide to make you acquainted with the main features of the portal",
    },
    {
      step: 2,
      element: "#navbar",
      intro: `PROFILE - leads to the user's profile<br>
      HOME - leads to the home page<br>
      SUPPLIERS - leads to the page with suppliers list<br>
      VIEW ORDERS - leads to the page with all orders<br>
      WAREHOUSE - leads to the page with all warehouses<br>
      TUTORIAL - opens tutorial<br>
      LOGOUT - logs the user out of the portal`,
    },
    {
      step: 3,
      element: "#number-orders",
      intro:
        "the number displays the number of all orders with the corresponding status",
      position: "top",
    },
    {
      step: 4,
      element: "#line-chart",
      intro:
        "a line chart displaying the number of orders which gained the corresponding status for the last 7 days",
      position: "right",
    },
    {
      step: 5,
      element: "#widget-orders",
      intro:
        "by clicking corresponding area, the page with orders filtered by the corresponding status are displayed",
    },
    {
      step: 6,
      element: "#order-list",
      intro: "this section of the platform displays the list of all orders",
      path: "/example/orders",
    },
    {
      step: 7,
      element: "#search-orders",
      intro: "search is done by an order number, is done by partial match",
      path: "/example/orders",
    },
    {
      step: 8,
      element: "#status-filter",
      intro:
        "filter orders by it's status, chosen from the dropdown list, possible options: New Order; Canceled; Confirmed; Picked Up.",
      path: "/example/orders",
    },
    {
      step: 9,
      element: "#shipping-filter",
      intro:
        "filter orders by shipping providers, chosen from the dropdown list, possible options: FedEx; Roadie",
      path: "/example/orders",
    },
    {
      step: 10,
      element: "#assigned-period-filter",
      intro: "filter orders by the assigned dates.",
      path: "/example/orders",
    },
    {
      step: 11,
      element: "#require-ship-period-filter",
      intro:
        "filter orders by required ship dates. A period should be chosen. Dates are chosen from the date picker",
      path: "/example/orders",
    },
    {
      step: 12,
      element: "#order-details", // order page
      intro:
        "this section of the portal displays the details of the separate order",
      path: "/example/order",
    },
    {
      step: 13,
      element: "#order-list-table", // order list page
      intro: `NUMBER OF ORDER - displays the number of the order<br>
      SHIPMENT NUMBER - display the shipment number of the order<br>
      CUSTOMER NAME - displays the customer name gained<br>
      ASSIGNED DATE - the date when the order was assigned to the supplier<br>
      PRODUCT - the name of the products<br>
      STATUS - the status of the shipment; possible options: New Order; Canceled; Confirmed; Picked Up By Carrier.<br>
      SHIPPING - the name of the transport company, which will ship the order<br>`,
      path: "/example/orders",
      position: "bottom",
    },
    {
      step: 14,
      element: "#show-order",
      intro: "opens the page with order details",
      path: "/example/orders",
      position: "left",
    },
    {
      step: 15,
      element: "#csv-generator",
      intro: "generates .csv file with orders depending on filters chosen",
      path: "/example/orders",
      position: "left",
    },
    {
      step: 16, // order page
      element: "#product-info",
      intro: `NUMBER OF ORDER - displays the number of the order<br>
      SHIPMENT NUMBER - display the shipment number of the order<br>
      ASSIGNED DATE - the date when the order was assigned to the supplier<br>
      CUSTOMER NAME - displays the customer name<br>
      SKU - product’s SKU<br>
      SIZE - Length*Width*Height of the product<br>
      PRODUCT - the name of the products in the order<br>
      PRICE - Price of the product<br>
      QUANTITY - Quantity of the product<br>
      TOTAL PRICE - Total price of the order<br>`,
      path: "/example/order",
      position: "right",
    },
    {
      step: 17,
      element: "#shipping-address",
      intro: `SHIP FROM - the address of the warehouse where the order will be taken from<br>
      SHIP TO - the address where the order should be delivered to<br>`,
      path: "/example/order",
      position: "right",
    },
    {
      step: 18,
      element: "#shipping-labels",
      intro: `SHIPPING METHOD -  the name of the shipping company which will deliver the order<br>
      TRACKING NUMBER - tracking number of the order<br>
      CREATED AT - the date of the order generation<br>`,
      path: "/example/order",
      position: "right",
    },
    {
      step: 19,
      element: "#shipping-label-pdf",
      intro: "downloads a pdf file with the label in a separate tab",
      path: "/example/order",
      position: "right",
    },
    {
      step: 20,
      element: "#cancel-order",
      intro: "cancels the order, the order is assigned the status “canceled”",
      path: "/example/order",
      position: "right",
    },
    {
      step: 21,
      element: "#mark-as-shipped-order",
      intro: "assigns the order the staus “Confirmed”",
      path: "/example/order",
      position: "right",
    },
    {
      step: 22,
      element: "#warehouses",
      intro:
        "this section of the portal displays all warehouses which belong to the supplier",
      path: "/example/warehouses",
    },
    {
      step: 23,
      element: "#filter-warehouses",
      intro: `SEARCH BY NAME - search is done by a warehouse name, is  done by partial match<br>
      SEARCH BY PHONE NUMBER- search is done by a warehouse phone number, is done by partial match<br>
      SEARCH BY ZIP - search is done by a warehouse zip code, is  done by partial match<br>`,
      path: "/example/warehouses",
    },
    {
      step: 24,
      element: "#warehouses-table",
      intro: `NUMBER  - displays the serial number of the warehouse<br>
      NAME - displays the name of the warehouse<br>
      PHONE - displays the phone number of the warehouse <br>
      ZIP - displays the zip code of the warehouse <br>`,
      path: "/example/warehouses",
    },
    {
      step: 25,
      element: "#edit-warehouse",
      intro: "opens the page with the warehouse details in an edit mode",
      path: "/example/warehouses",
      position: "left",
    },
    {
      step: 26,
      element: "#warehouse-modal-content",
      intro: "warehouse details",
      path: "/example/warehouse/edit",
      position: "top",
    },
  ],
};

function beforeChangeCallback(el) {
  const nextStepItem = this._introItems[this._currentStep];
  const nextStepPath =
    nextStepItem.path || localStorage.getItem("startTourPath") || "/";

  if (window.location.pathname !== nextStepPath) {
    localStorage.setItem("nextTourStep", nextStepItem.step);
    Turbolinks.visit(nextStepPath);
  }
}

function afterChangeCallback() {
  $(".introjs-tooltipbuttons > a").each(function () {
    $(this).removeClass("introjs-button").addClass("btn btn-sm btn-secondary");
  });
}

function exitFromTour() {
  const startTourPath = localStorage.getItem("startTourPath");
  localStorage.removeItem("startTourPath");

  Turbolinks.visit(startTourPath);
}

function startTour(step) {
  let tour = introJs();

  tour.setOptions(tourOptions);
  tour.onafterchange(afterChangeCallback);
  tour.start();

  if (step) tour.goToStepNumber(step);
  else localStorage.setItem("startTourPath", window.location.pathname);

  tour.onbeforechange(beforeChangeCallback);
  tour.onexit(exitFromTour);
  tour.oncomplete(exitFromTour);

  return tour;
}

$(document).on("turbolinks:load", function () {
  if ($("#start-tour")[0] && window.location.search.includes("welcome=true"))
    startTour();

  if (localStorage.getItem("nextTourStep")) {
    startTour(parseInt(localStorage.getItem("nextTourStep")));
    localStorage.removeItem("nextTourStep");
  }

  $("#start-tour").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    startTour();
  });
});

import { Controller } from 'stimulus';

export default class extends Controller {
  send(event) {
    event.preventDefault();
    const url = window.location.pathname + '?' + $(this.element).serialize();
    Turbolinks.visit(url)
  }
}


import { Controller } from 'stimulus';
import 'src/core-ui/coreui-chartjs.bundle';

export default class extends Controller {
  static targets = ["canvas"];
  static values = { dataSet: Object };

  connect() {
    drawStatusChart(this.canvasTarget.getAttribute('id'),
      Object.keys(this.dataSetValue).reverse(),
      Object.values(this.dataSetValue).reverse());
  }
}

function drawStatusChart(canvasID, labels, dataset) {
  return new Chart(document.getElementById(canvasID), {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        label: canvasID.split('_').join(' '),
        backgroundColor: 'transparent',
        borderColor: 'rgba(255,255,255,.55)',
        data: dataset
      }]
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      layout: {
        padding: {
          top: 5
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: 'transparent',
            zeroLineColor: 'transparent'
          },
          ticks: {
            fontSize: 2,
            fontColor: 'transparent'
          }
        }],
        yAxes: [{
          display: false,
          ticks: {
            display: false,
          }
        }]
      },
      elements: {
        line: {
          borderWidth: 1
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4
        }
      }
    }
  });
};

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input", "icon"];

  toggle() {
    const iconText = this.iconTarget.textContent === "visibility_off" ?  "visibility" : "visibility_off"
    const inputType = this.inputTarget.getAttribute("type") === "password" ? "text" : "password"

    this.iconTarget.textContent = iconText
    this.inputTarget.setAttribute("type", inputType)
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["submit"];

  toggleSubmit(event) {
    event.preventDefault();

    var disabledState = false;
    var form = $(this.element);

    form.find('[required]').each(function(i, el) {
      if(!el.value) return disabledState = true;
      if(el.type === 'radio') {
        var checkedRadioButton;
        checkedRadioButton = form.find(`[name="${el.name}"]`).toArray().find(function(radio) { return radio.checked });

        if(!checkedRadioButton) return disabledState = true;
      }
    });

    $(this.submitTarget).prop('disabled', disabledState);
  }
}

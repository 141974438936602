import { Controller } from 'stimulus';
import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

export default class extends Controller {
  connect() {
    var dropzoneElement = this.element
    $(dropzoneElement).dropzone({
      url: '/',
      clickable: '.browse-files',
      acceptedFiles: 'image/*',
      maxFiles: 1,
      maxFilesize: 9,
      autoProcessQueue: false,
      addRemoveLinks: false,
      init: function() {
        this.on("maxfilesexceeded", function(file) {
          this.removeAllFiles();
          this.addFile(file);
        });
      },
      thumbnail: function(file, dataURL) {
        $(this.element).find('img').attr('src', dataURL);
        $(document).trigger('dropzoneController.addFile', [this.element, file])
      }
    })
  }
}

import { Controller } from 'stimulus';
import debounce from 'lodash.debounce'

export default class extends Controller {
  initialize() {
    this.autosend = debounce(this.send, 1500).bind(this);
  }

  connect() {
    $(this.element).on('change', e => this.send(e))
  }

  send(event) {
    event.preventDefault();
    const url = window.location.pathname + '?' + $(this.element).serialize();

    history.pushState({turbolinks: true, url: url}, document.title, url);
    $.ajax({ url: url, dataType: 'script' });
  }
}


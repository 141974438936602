import { Controller } from 'stimulus';
import 'src/core-ui/select2.full.min';

export default class extends Controller {
  connect() {
    $(this.element).select2({ placeholder: 'All', templateResult: templateResult, closeOnSelect: false })
    $(this.element).on('select2:select', changeOptionCallback)
    $(this.element).on('select2:unselect', changeOptionCallback)
  }
}

function templateResult(state) {
  return $(`<input type="checkbox" class="mr-2 mt-1" ${state.selected ? 'checked' : ''}><span>${state.text}</span>`)
}

function changeOptionCallback(e) {
  var checkbox = $(`[id='${e.params.data._resultId}'] > input[type=checkbox]`);
  if(!$(e.params.originalEvent.target).is('input[type=checkbox]')) {
    checkbox.prop('checked', e.params.data.selected)
  }
}

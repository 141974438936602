import { Controller } from 'stimulus';
import 'src/core-ui/daterangepicker';

export default class extends Controller {
  connect() {
    $(this.element).daterangepicker({ autoUpdateInput: false })
    $(this.element).on('apply.daterangepicker', function(e, picker){
      $(e.target).val(`${picker.startDate.format('L')} - ${picker.endDate.format('L')}`).change();
    });
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["templateItem", "list", "item", "remove"]

  add(event) {
    event.preventDefault();
    var item = $(this.templateItemTarget).html();
    $(this.listTarget).append(item);
  }

  remove(event) {
    event.preventDefault();
    const index = this.removeTargets.findIndex(t => t === event.target);
    $(this.itemTargets[index]).remove();
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["description", "correctPrice", "container", "reason"]

  rerender() {
    const reason = this.reasonTarget.value

    if (reason === 'incorrect price') {
      this.containerTarget.innerHTML = this.correctPriceTarget.innerHTML
      $(this.submitTarget).prop('disabled', true)
    } else if (reason === 'other') {
      this.containerTarget.innerHTML = this.descriptionTarget.innerHTML
      $(this.submitTarget).prop('disabled', true)
    } else {
      this.containerTarget.innerHTML =  ''
      $(this.submitTarget).prop('disabled', false)
    }
  }
}

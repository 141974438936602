import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $(document).on('dropzoneMultiController.addFile', function(event, element, file, dataURL) {
       var formGroup = $(file.previewElement).closest('.form-group');
       $('<input>').attr('type', 'hidden')
         .attr('value', dataURL)
         .attr('id', `filedata-${file.upload.uuid}`)
         .attr('name', 'tpms_info[response][images][][data]').appendTo(formGroup);
       $('<input>').attr('type', 'hidden')
         .attr('value', file.name)
         .attr('id', `filename-${file.upload.uuid}`)
         .attr('name', 'tpms_info[response][images][][name]').appendTo(formGroup);
    });

    $(document).on('dropzoneMultiController.removeFile', function(event, element, file) {
      $(file.previewElement).closest('.form-group').find(`input#filename-${file.upload.uuid}`).remove();
      $(file.previewElement).closest('.form-group').find(`input#filedata-${file.upload.uuid}`).remove();
      file.previewElement.remove();
    });
  }

  disconnect() {
    $(document).off('dropzoneMultiController.addFile');
    $(document).off('dropzoneMultiController.removeFile');
  }
}
